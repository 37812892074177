<template>
  <div>
    <!--    <div class="d-flex ">
      <div class="mx-3"></div>
    </div> -->
    <div class="inside-menu mx-3">
      <div
        class="side-header light-skin opened-menu"
        :class="{ 'nav-width': $store.state.hamburger }"
      >
        <div class="">
          <div class="side-menus">
            <nav class="pt-0">
              <div
                class="icon-active-color border-bottom border-Ttop"
                v-for="menu in navigations.menu"
                :key="menu.name"
              >
                <router-link :to="menu.path + navigations._id">
                  <div class="link-text p-3 text-uppercase">
                    <font-awesome-icon
                      :icon="['fas', menu.icon]"
                      v-if="menu.icon"
                      class="fa-1x mr-2"
                    />{{ menu.name }}
                  </div>
                </router-link>
              </div>
            </nav>
          </div>
        </div>
        <!-- Menu Scroll -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  props: {
    navigations: {
      type: Object,
      default() {
        return undefined; // The default value you want to set.
      }
    }
  },
  data() {
    return {
      isActive: false
    };
  },

  methods: {
    ...mapMutations({
      hamburgerChange: "changeHamburger" // map `this.increment()` to `this.$store.commit('increment')`
    })
  }
};
</script>
<style lang="scss" scoped>
.inside-menu {
  .side-header {
    height: auto;
    width: 250px;
    margin-top: 25%;
  }
  .side-header.light-skin {
    background: #ffffff none repeat scroll 0 0;
  }
}
</style>
