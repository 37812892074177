<template>
  <div>
    <headerCustom></headerCustom>
    <div class="template main-content sidenav-part ng-scope">
      <div class="d-flex">
        <div class="mx-3">
          <div class="heading-sec">
            <div class="row">
              <div class="col-md-4 column">
                <div class="heading-profile">
                  <h3>Transaction</h3>
                </div>
              </div>
            </div>
          </div>
          <!-- Top Bar Chart -->
          <div class="panel-content">
            <div class="row">
              <div class="col-md-12">
                <div class="widget">
                  <div class="table-area">
                    <div class="widget-title">
                      <div class="float-right">
                        <form class="search-form mr-form">
                          <input
                            type="text"
                            class="form-control"
                            v-model="searchText"
                            @input="viewTransactionList(1)"
                            placeholder="Search Here..."
                          />
                        </form>
                      </div>
                      <h3>
                        List of Transactions
                        <span class="tableTotal">(Total : {{ totalCount }})</span>
                      </h3>
                    </div>
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Transaction Id</th>
                            <th>Transaction Status</th>
                            <th>Hotel Name</th>
                            <th>User Name</th>
                            <!-- <th>Package Name</th> -->
                          </tr>
                        </thead>
                        <tbody v-if="transactions.length > 0">
                          <tr
                            v-for="(transaction, index) in transactions"
                            v-bind:key="transaction._id"
                          >
                            <td>{{ index + 1 + (page - 1) * 10 }}</td>

                            <td>
                              <div>{{ transaction.transactionId }}</div>
                            </td>
                            <td>
                              <div>{{ transaction.status }}</div>
                            </td>
                            <td>
                              <div>{{ transaction.hotel.name }}</div>
                            </td>
                            <td>
                              <div>{{ transaction.user.name }}</div>
                            </td>
                            <!-- <td>
                              <div>{{ Transactions.package1.name }}</div>
                            </td> -->
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div
                      class="table-body-contents text-center m-3 font-size-md font-weight-bold text-muted"
                      v-if="transactions.length === 0"
                    >
                      No Data Found
                    </div>
                    <div class="text-center" v-if="transactions.length >= 1">
                      <b-pagination
                        class="mb-0 float-right"
                        v-model="page"
                        align="center"
                        :total-rows="totalCount"
                        :per-page="formData.limit"
                        @input="viewTransactionList(page)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <sideMenu :navigations="navigation" />
      </div>
    </div>
  </div>
</template>

<script>
import headerCustom from "@/components/Header.vue";
import sideMenu from "@/components/subheader.vue";
import apiservice from "@/service/apiservice";

export default {
  components: {
    headerCustom,
    sideMenu
  },
  data() {
    return {
      navigation: {},
      menu: [
        {
          name: "hotel details",
          path: "/hotel/"
        },
        {
          name: "Transaction",
          path: "/transaction-details/"
        }
      ],
      totalItems: 0,
      id: "",
      logs: {
        idToDelete: ""
      },
      currentPage: 1,
      searchText: "",
      transactions: [],
      page: 1,
      formData: { limit: 10 },
      totalCount: 0,
      perPage: 10,
      errors: [],
      data: ""
    };
  },
  created() {
    this.navigation._id = this.$route.params.id;
    this.navigation.menu = this.menu;
    this.id = this.navigation._id;
    this.viewTransactionList(this.page);
  },
  methods: {
    viewTransactionList(pageNo) {
      this.formData.id = this.id;
      this.formData.page = pageNo;
      this.formData.limit = this.perPage;
      this.formData.search = this.searchText;
      apiservice.viewTransactionList(this.formData, data => {
        if (data.code === 200) {
          this.transactions = data.data;

          this.totalCount = data.data.length;
        } else {
        }
      });
    }
  }
};
</script>
